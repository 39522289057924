/* @media screen and (max-width: 400px) {
  #about,
  #services,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
} */

/* Default styles for larger screens (e.g., laptops and desktops) */
#about,
#services,
#contact,
#footer,
#portfolio {
  width: 100%;
}

/* Styles for tablets and smaller screens (up to 768px) */
@media screen and (max-width: 768px) {
  #about,
  #services,
  #contact,
  #footer,
  #portfolio {
    width: 100%;
    /* Other adjustments specific to tablet view */
  }
}

/* Styles for mobile screens (up to 480px) */
@media screen and (max-width: 480px) {
  #about,
  #services,
  #contact,
  #footer,
  #portfolio {
    width: 100%;
    margin: 0; /* Ensure no extra margin causing overflow */
    padding: 0; /* Adjust padding as needed */
  }
  .intro h1 {
    font-size: 36px; /* Adjust font size for mobile */
  }
  .intro p {
    font-size: 16px; /* Adjust font size for mobile */
  }
  #menu.navbar-default .navbar-nav > li > a {
    margin: 9px 10px 0; /* Reduce margin */
  }
  .section-title h2 {
    margin: 10px 0; /* Adjust margins */
    padding-bottom: 10px; /* Adjust padding */
  }
  .section-title h2::after {
    margin-left: -30px; /* Adjust positioning */
    left: 50%;
  }
}
